import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Modello } from "../../queries/veicoli";

export enum EEngines {
  _ = "Seleziona l'alimentazione",
  D = "Diesel",
  G = "Benina",
  N = "Diesel MHEV",
  Z = "Benzina PHEV",
  X = "Elettrico",
}

export interface SettingsState {
  token: string;
  modelli: Array<Modello>;
}

const initialState: SettingsState = {
  token: "",
  modelli: [
    { value: "81", label: "Transit V363 TA" },
    { value: "87", label: "Nuovo Custom" },
    { value: "95", label: "Nuovo Courier" },
    { value: "68", label: "Ranger" },
  ],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string[]>) => {
      state.token = action.payload[0];
      action.payload.length > 1 &&
        localStorage.setItem("refreshToken", action.payload[1]);
    },
  },
});

export const { setToken } = settingsSlice.actions;

export const selectToken = (state: RootState) => state.settings.token;
export const selectModelli = (state: RootState) => state.settings.modelli;

export default settingsSlice.reducer;

// @ts-nocheck
import { Container, Flex } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  EPreventivoStatus,
  setInitialPreventivo,
} from "../../../features/preventivo/preventivoSlice";
import {
  useGetTestisQuery,
  usePutNotificationMutation,
  usePutPreventivoMutation,
} from "../../../features/api/apiSlice";
import { selectActiveStep, setActiveStep } from "../../../features/ui/uiSlice";
import { Preventivo } from "../../../queries/preventivi";
import TotalBox from "../../../components/Preventivo/TotalBox/TotalBox";
import SelectIva from "../../../components/Preventivo/PreventivoInputs/SelectIva";
import { useEffect, useState } from "react";
import PreventivoSteps from "../../../components/Preventivo/PreventivoSteps/PreventivoSteps";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BottomActions from "./BottomActions/BottomActions";
import CustomerVehicleBox from "../../../components/Preventivo/CustomerVehicleBox/CustomerVehicleBox";
import usePreventivoData from "../../../hooks/usePreventivoData";
import { notifications } from "@mantine/notifications";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Step4 from "./Step4/Step4";
import TopActions from "./TopActions/TopActions";
import TotalDetails from "../../../components/Preventivo/TotalBox/TotalDetails/TotalDetails";
import {
  encodePreventivoString,
  getTemplateString,
} from "../../../utils/utils";

const NewPreventivo = () => {
  const authUser = useAuthUser();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [putPreventivo, result] = usePutPreventivoMutation();
  const { data: testi } = useGetTestisQuery({
    limit: 10000,
  });
  const [putNotification] = usePutNotificationMutation();
  const activeStep = useAppSelector(selectActiveStep);
  let { preventivoId } = useParams();

  const {
    currentCliente,
    temperatura,
    model,
    listino,
    optionals,
    priceFinal,
    preventivo,
    permuteTotal,
    permute,
    listinoPrice,
    totalDiscountedNoIva,
    servizi,
    accessori,
    engine,
    veicolo,
    sconti,
    scontoGrandTotal,
  } = usePreventivoData({ preventivoId });
  const [isSavingEnabled, setIsSavingEnabled] = useState<{
    state: boolean;
    isLoading: boolean;
  }>({
    state: true,
    isLoading: false,
  });
  const firstStepCompleted =
    currentCliente && temperatura && model && listino && engine && veicolo;

  const saveLabel = !preventivoId
    ? "Salva preventivo"
    : location.pathname === `/preventivi/duplica/${preventivoId}`
      ? "Duplica preventivo"
      : "Modifica Preventivo";

  useEffect(() => {
    dispatch(setActiveStep(1));
  }, []);

  const handleSavePreventivo = () => {
    const payload: Preventivo = {
      ...preventivo,
      modello: encodePreventivoString(
        getTemplateString("MODELLO", preventivo?.veicolo, testi?.testis ?? []),
      ),
      oggetto: encodePreventivoString(
        getTemplateString("OGGETTO", preventivo?.veicolo, testi?.testis ?? []),
      ),
      motore: encodePreventivoString(
        getTemplateString("MOTORE", preventivo?.veicolo, testi?.testis ?? []),
      ),
      dimensioni: encodePreventivoString(
        getTemplateString(
          "DIMENSIONI",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_guida: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_ASSISTENZA_ALLA_GUIDA",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_interne: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_INTERNE",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_sicurezza: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_SICUREZZA",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_tecnologia: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_TECNOLOGIA",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      caratteristiche_versione: encodePreventivoString(
        getTemplateString(
          "CARATTERISTICHE_VERSIONE",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      agente: {
        ...authUser.custom_data,
        _id: authUser._id,
        email: authUser.data.email,
      },
      status: EPreventivoStatus.DRAFT_SAVED,
      price_total: priceFinal,
    };

    setIsSavingEnabled({
      state: false,
      isLoading: true,
    });

    putPreventivo(payload)
      .unwrap()
      .then((response) => {
        dispatch(setInitialPreventivo(payload));

        notifications.show({
          withBorder: true,
          withCloseButton: true,
          autoClose: 4000,
          position: "top-center",
          title: "Successo!",
          message: !preventivoId
            ? "Preventivo creato con successo."
            : location.pathname === `/preventivi/duplica/${preventivoId}`
              ? "Preventivo duplicato con successo."
              : "Preventivo modificato con successo.",
          color: "teal",
          radius: "md",
          loading: false,
        });

        setIsSavingEnabled({
          state: false,
          isLoading: false,
        });

        putNotification({
          id_agente_from: authUser._id,
          nome_agente_from: authUser.custom_data.name,
          id_agente_to: authUser._id,
          nome_agente_to: authUser.custom_data.name,
          id_preventivo: response.upsertOnePreventivi._id,
          text: "Test notifica automatica",
        });

        //@ts-ignore -- non cè modo di fare casting di response
        navigate(`/preventivi/modifica/${response.upsertOnePreventivi._id}`);
        dispatch(setActiveStep(4));
      })
      .catch((error) => {
        console.error("rejected", error);
        notifications.show({
          withCloseButton: true,
          position: "top-center",
          title: "Errore",
          radius: "md",
          message: !preventivoId
            ? "Errore nel salvataggio del preventivo. Riprova."
            : location.pathname === `/preventivi/duplica/${preventivoId}`
              ? "Errore nella duplicazione del preventivo. Riprova."
              : "Errore nella modifica del preventivo. Riprova.",
          color: "pink",
          loading: false,
        });
        setIsSavingEnabled({
          state: true,
          isLoading: false,
        });
      });
  };

  return (
    <>
      <TopActions
        preventivoId={preventivoId}
        currentCliente={currentCliente}
        veicolo={veicolo}
      />
      <Container
        mb="40px"
        size="100%"
        px="40"
        py="40"
        className={`${activeStep !== 4 ? "flex items-start gap-11" : ""}`}
      >
        <div className="w-full">
          {activeStep === 1 && (
            <Step1
              model={model}
              veicolo={veicolo}
              engine={engine}
              listino={listino}
            />
          )}
          {activeStep === 2 && <Step2 />}
          {activeStep === 3 && (
            <Step3
              veicolo={veicolo}
              optionals={optionals}
              accessori={accessori}
              servizi={servizi}
              permuteTotal={permuteTotal}
              permute={permute}
              sconti={sconti}
              sconto={scontoGrandTotal}
              scontoGrandTotal={scontoGrandTotal}
            />
          )}
          <BottomActions
            activeStep={activeStep}
            firstStepCompleted={firstStepCompleted}
            isSavingEnabled={isSavingEnabled}
            handleSavePreventivo={handleSavePreventivo}
            onPrevClick={() => dispatch(setActiveStep(activeStep - 1))}
            onNextClick={() => dispatch(setActiveStep(activeStep + 1))}
            saveLabel={saveLabel}
          />
        </div>
        {activeStep !== 4 && (
          <div className="sticky top-36 min-w-80">
            <TotalDetails />
          </div>
        )}
        {activeStep === 4 && <Step4 />}
      </Container>
    </>
  );
};

export default NewPreventivo;

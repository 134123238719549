import VariabiliTable from "../../components/Admin/AdminTable/VariabiliTable"
import VariabileForm from "../../components/Form/VariabileForm"
import { modals } from "@mantine/modals"
import { Button, Container } from "@mantine/core"
import { IconCirclePlus } from "@tabler/icons-react"

const AdminVariabili = () => {
  return (
    <>
      <section className="px-10 py-5">
        <div className="flex gap-5">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconCirclePlus size={18} />}
            onClick={() => {
              modals.open({
                padding: 0,
                centered: true,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                children: (
                  <VariabileForm onCloseClick={() => modals.closeAll()} />
                ),
              })
            }}
          >
            Nuova variabile
          </Button>
        </div>
      </section>
      <Container
        size="100%"
        className="mx-10 rounded-xl bg-transparent p-0 shadow-md"
      >
        <VariabiliTable />
      </Container>
    </>
  )
}

export default AdminVariabili

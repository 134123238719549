import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Button } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { DetailRowObject } from "./DetailRow/DetailRowTypes";
import {
  selectAccessoriValues,
  selectAnticipo,
  selectFerroValues,
  selectMargine,
  selectOptionalsValues,
  selectPriceFinal,
  selectServiziValues,
  selectTotalBeforeDiscount,
  selectTotaleVeicolo,
  selectTotalPermute,
  selectTotalVariations,
} from "../../../../features/preventivo/preventivoSlice";
import DetailRow from "./DetailRow/DetailRow";

const TotalDetails = () => {
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const totalBeforeDiscount = useAppSelector(selectTotalBeforeDiscount);
  const totalVariations = useAppSelector(selectTotalVariations);
  const priceFinal = useAppSelector(selectPriceFinal);
  const permute = useAppSelector(selectTotalPermute);
  const margine = useAppSelector(selectMargine);
  const anticipo = useAppSelector(selectAnticipo);
  const ferroTotal = useAppSelector(selectFerroValues);
  const optionalTotal = useAppSelector(selectOptionalsValues);
  const accessoriTotal = useAppSelector(selectAccessoriValues);
  const totaleVeicolo = useAppSelector(selectTotaleVeicolo);
  const serviziTotal = useAppSelector(selectServiziValues);

  const handleClick = () => {
    setShowDetailPanel((prevState) => !prevState);
  };

  const detailRows: Array<DetailRowObject> = Array.from([
    {
      label: "Veicolo Base",
      price: ferroTotal[0],
    },
    {
      label: "Optional Ford",
      price: optionalTotal[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Totale Listino",
      price: totalBeforeDiscount,
    },
    {
      label: "Accessori - Allestimenti",
      price: accessoriTotal[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Totale Veicolo",
      price: totaleVeicolo,
    },
    {
      label: "Sconti su listino",
      price: 0,
      isNegative: true,
    },
    {
      label: "Sconto",
      price: 0,
      isNegative: true,
    },
    {
      label: "Servizi, messa su strada ecc.",
      price: serviziTotal[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Prezzo finale",
      price: priceFinal,
      priceClass: "text-base",
    },
    {
      label: "Permute",
      price: priceFinal,
      isNegative: true,
    },
    /*
    {
      label: "IVA",
      // TODO: Portare nello slice con selector
      price: ivaAmount,
      rowClass: "pb-3 mb-3 border-b border-b-white",
    },
    */
  ]);
  return (
    <div className="z-10 rounded-xl border bg-white px-6 py-4 text-slate-600 shadow-md">
      <span className="mb-2 block text-sm font-semibold uppercase">
        Dettagli prezzo
      </span>
      {detailRows.map((detail: DetailRowObject, index: number) => (
        <DetailRow
          key={index}
          label={detail.label}
          price={detail.price}
          rowClass={detail.rowClass}
          priceClass={detail.priceClass}
          isNegative={detail.isNegative}
        />
      ))}
      {showDetailPanel && (
        <DetailRow
          key={"margine"}
          label="Margine"
          price={margine}
          priceClass="bg-cm-green text-white"
        />
      )}
      <div className="flex justify-end">
        <Button
          variant="transparent"
          p={0}
          h={16}
          className="text-[9px] uppercase text-slate-600"
          onClick={handleClick}
          rightSection={
            <IconChevronDown
              className={`h-4 w-4 ${showDetailPanel ? "rotate-180" : ""}`}
            />
          }
        >
          Dettagli
        </Button>
      </div>
    </div>
  );
};

export default TotalDetails;

import { gql } from "@apollo/client";
import { EEngines } from "../features/settings/settingsSlice";
import { BSON } from "realm-web";

export interface Modello {
  value: string;
  label: string;
}
export interface Veicolo {
  _id?: string;
  id?: string;
  description?: string;
  listino?: string;
  price?: number;
  model: string;
  engine?: EEngines;
  optionals?: Array<string>;
  portata?: string;
  altezza?: string;
  lunghezza?: string;
  cv?: string;
  kw?: string;
  ps?: string;
  mm_vano?: string;
  cambio?: string;
  dimensioni?: string;
  dotazioni_assistenza_alla_guida?: string;
  dotazioni_interne?: string;
  dotazioni_sicurezza?: string;
  dotazioni_tecnologia?: string;
  modello_modelyear?: string;
  modello_tipologia?: string;
  modello_veicolo?: string;
  modello_versione?: string;
  motore?: string;
  pc_tipologia_hl?: string;
  caratteristiche_versione?: string;
}

export enum EOptionType {
  OPTIONALS = "O",
  ACCESSORI = "A",
  SERVIZI = "S",
}
export interface Option {
  _id?: string;
  id: string;
  label: string;
  listino?: string;
  price: number;
  cm_type: EOptionType;
  obbligatorio?: string;
  veicolo?: string;
  rif_pagamento?: string;
}

export interface GetVeicolieResponse {
  veicolis: Veicolo[];
}

export interface AnagraficaResponse {
  data: {
    veicoli: Veicolo;
  };
}

export interface VeicolisQuery {
  limit?: number;
  model?: string;
  engine?: string;
  listino?: string;
}
export interface GetOptionalResponse {
  optionals: Option[];
}

export interface OptionalsQuery {
  limit?: number;
  veicolo?: string;
  listino?: string;
  type?: EOptionType;
}

export interface ListiniPerModelloQuery {
  model?: string;
}

export interface EnginesPerModelloListinoQuery {
  model?: string;
  listino?: string;
}

export interface GetListiniResponse {
  listini: string[];
}

export interface GetEnginesResponse {
  engines: string[];
}

export const queryVeicoli = ({
  limit,
  model,
  listino,
  engine,
}: VeicolisQuery) => ({
  document: gql`
    query {
      veicolis (
        limit: 500, 
        sortBy: _ID_DESC,
        query: {model: "${model}", listino: "${listino}", engine: "${engine}"}
      ) {
        id
        _id
        description
        model
        listino
        engine
        price
        optionals
        portata
        altezza
        lunghezza
        cv
        kw
        ps
        mm_vano
        cambio
        dimensioni
        dotazioni_assistenza_alla_guida
        dotazioni_interne
        dotazioni_sicurezza
        dotazioni_tecnologia
        modello_modelyear
        modello_tipologia
        modello_veicolo
        modello_versione
        motore
        pc_tipologia_hl
        caratteristiche_versione
      }
    }
  `,
});

export const queryOptionals = ({
  limit,
  veicolo,
  listino,
  type,
}: OptionalsQuery) => ({
  document: gql`
    query ($veicolo: String, $listino: String, $type: String) {
      optionals(
        limit: 10000
        sortBy: _ID_DESC
        query: { veicolo: $veicolo, listino: $listino, cm_type: $type }
      ) {
        id
        _id
        label
        cm_type
        price
        listino
        obbligatorio
        veicolo
      }
    }
  `,
  variables: {
    veicolo,
    listino,
    type,
  },
});

export const queryListiniPerModello = ({ model }: ListiniPerModelloQuery) => ({
  document: gql`
    query {
      listini_distinct(input: "${model}") {
        listini
      }
    }
  `,
});

export const queryEnginesPerModelloListino = ({
  model,
  listino,
}: EnginesPerModelloListinoQuery) => ({
  document: gql`
    query ($input: EnginesInput) {
      engines_distinct(input: $input) {
        engines
      }
    }
  `,
  variables: {
    input: {
      model,
      listino,
    },
  },
});

export const mutationVeicoli = (veicolo: Veicolo) => ({
  document: gql`
    mutation {
      upsertOneVeicoli(
        data: {
          id: "${veicolo.id}"
          description:  "${veicolo.description}"
          model: "${veicolo.model}"
          listino: "${veicolo.listino}"
          price: ${veicolo.price}
          engine: "${veicolo.engine}"
          optionals: "${veicolo.optionals}"
          portata: "${veicolo.portata || ""}"
          altezza: "${veicolo.altezza || ""}"
          lunghezza: "${veicolo.lunghezza || ""}"
          cv: "${veicolo.cv || ""}"
          kw: "${veicolo.kw || ""}"
          ps: "${veicolo.ps || ""}"
          mm_vano: "${veicolo.mm_vano || ""}"
          modello_modelyear: "${veicolo.modello_modelyear || ""}"
          cambio: "${veicolo.cambio || ""}"
          dimensioni: "${veicolo.dimensioni || ""}"
          dotazioni_assistenza_alla_guida: "${veicolo.dotazioni_assistenza_alla_guida || ""}"
          dotazioni_interne: "${veicolo.dotazioni_interne || ""}"
          dotazioni_sicurezza: "${veicolo.dotazioni_sicurezza || ""}"
          dotazioni_tecnologia: "${veicolo.dotazioni_tecnologia || ""}"
          modello_veicolo: "${veicolo.modello_veicolo || ""}"
          modello_versione: "${veicolo.modello_versione || ""}"
          motore: "${veicolo.motore || ""}"
          pc_tipologia_hl: "${veicolo.pc_tipologia_hl || ""}"
          caratteristiche_versione: "${veicolo.caratteristiche_versione || ""}"
        }
        query: {
          _id: "${veicolo._id ? new BSON.ObjectID(veicolo._id) : new BSON.ObjectID()}"
        }) {
        _id
      }
    }
  `,
});

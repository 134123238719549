import { Loader } from "@mantine/core"
import { useAppDispatch } from "../../../app/hooks"
import { Veicolo } from "../../../queries/veicoli"
import { setAdminCurrentVeicolo } from "../../../features/ui/uiSlice"
import AdminVeicoloRow from "../AdminVeicoloRow/AdminVeicoloRow"

interface IVeicoliTableProps {
  data: Veicolo[]
  isLoading: boolean
  adminCurrentVeicolo: Veicolo | null
}

const VeicoliTable = ({
  data,
  isLoading,
  adminCurrentVeicolo,
}: IVeicoliTableProps) => {
  const dispatch = useAppDispatch()
  const handleCurrentVeicoloChange = (veicolo: Veicolo) => {
    dispatch(setAdminCurrentVeicolo(veicolo))
  }
  return (
    <div className="bg-white rounded-xl px-6 py-8">
      {isLoading ? (
        "..."
      ) : (
        <>
          {data?.map((veicolo: Veicolo) => (
            <AdminVeicoloRow
              key={veicolo._id}
              veicolo={veicolo}
              selectedVeicolo={adminCurrentVeicolo?._id || ""}
              onChange={() => handleCurrentVeicoloChange(veicolo)}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default VeicoliTable

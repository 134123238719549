import ModalLayout from "../Modal/ModalLayout/ModalLayout";
import { TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useForm } from "react-hook-form";
import { Testo } from "../../queries/testi";
import { usePutTestoMutation } from "../../features/api/apiSlice";
import { notifications } from "@mantine/notifications";

const VariabileForm = ({
  currentModalRecord,
  currentUserName,
  onCloseClick,
}: {
  currentModalRecord?: any;
  currentUserName?: string | undefined;
  onCloseClick: () => void;
}) => {
  const [putTesto] = usePutTestoMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  const onSubmit = (data: any) => {
    const variabile: Testo = {
      _id: currentModalRecord?._id,
      label: data.label,
      testo: data.testo,
    };

    putTesto(variabile)
      .unwrap()
      .then((payload) => {
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: !currentModalRecord
            ? "Variabile creata."
            : "Variabile modificata.",
          color: "teal",
          loading: false,
        });
        modals.closeAll();
      })
      .catch((error: Error) => {
        console.error(error);
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: !currentModalRecord
            ? "Errore nella creazione della variabile. Riprova."
            : "Errore nella modifica della variabile. Riprova.",
          color: "pink",
          loading: false,
        });
      });
    modals.closeAll();
  };

  const modalTitle = currentModalRecord
    ? "Modifica variabile"
    : "Crea nuova variabile";

  return (
    <ModalLayout
      title={modalTitle}
      currentUserName={currentUserName}
      onCloseClick={onCloseClick}
      onConfirmClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="mb-5 flex flex-col gap-2">
          <TextInput
            {...register("label", { required: true })}
            defaultValue={currentModalRecord ? currentModalRecord.label : ""}
            placeholder="Label"
            className="w-full"
          />
          <TextInput
            {...register("testo", { required: true })}
            defaultValue={currentModalRecord ? currentModalRecord.testo : ""}
            placeholder="Testo"
            className="w-full"
          />
        </div>
      </form>
    </ModalLayout>
  );
};

export default VariabileForm;

import { Select } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectEngine,
  selectListino,
  selectModel,
  selectVeicolo,
  setEngine,
} from "../../../features/preventivo/preventivoSlice";
import { useGetEnginesPerModelloListinoQuery } from "../../../features/api/apiSlice";
import { EEngines } from "../../../features/settings/settingsSlice";

function mapFn(value: string) {
  // @ts-ignore -- problema con enum spiegato qui-> https://thoughtbot.com/blog/the-trouble-with-typescript-enums
  return { value, label: EEngines[value] };
}

const SelectAlimentazione = () => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(selectModel);
  const listino = useAppSelector(selectListino);
  const disabled = !(model && listino);
  const { data } = useGetEnginesPerModelloListinoQuery(
    { model: model, listino: listino },
    { skip: disabled },
  );
  const dataForSelect = [
    { label: "Seleziona l'alimentazone", value: EEngines._ },
    ...(data?.engines.map(mapFn) || []),
  ];
  const currentEngine = useAppSelector(selectEngine);

  return (
    <Select
      label="Alimentazione"
      value={currentEngine}
      placeholder="Seleziona l'alimentazione"
      data={dataForSelect}
      onChange={(sel) => {
        // @ts-ignore -- problema con enum spiegato qui-> https://thoughtbot.com/blog/the-trouble-with-typescript-enums
        sel && dispatch(setEngine(sel));
      }}
      disabled={disabled}
      radius={12}
      styles={{ input: { height: 44 } }}
    />
  );
};

export default SelectAlimentazione;

import SelectAlimentazione from "../../../../components/Preventivo/PreventivoInputs/SelectAlimentazione"
import SelectCliente from "../../../../components/Preventivo/PreventivoInputs/SelectCliente"
import SelectListini from "../../../../components/Preventivo/PreventivoInputs/SelectListini"
import SelectModello from "../../../../components/Preventivo/PreventivoInputs/SelectModello"
import SelectVeicolo from "../../../../components/Preventivo/PreventivoInputs/SelectVeicolo"
import VeicoloSummary from "../../../../components/Preventivo/Summaries/VeicoloSummary"
import { EEngines } from "../../../../features/settings/settingsSlice"
import { Veicolo } from "../../../../queries/veicoli"

interface Step1Props {
  model: string | undefined
  listino: string | undefined
  engine: EEngines | undefined
  veicolo: Veicolo | undefined
}

const Step1 = ({ model, listino, engine, veicolo }: Step1Props) => {
  return (
    <div className="px-6 pt-8 pb-4 bg-white rounded-xl shadow-md border">
      <div className="mb-5">
        <SelectCliente />
      </div>
      <div className="mb-5 grid grid-cols-2 gap-10">
        <SelectModello />
        <SelectListini />
      </div>
      <div className="mb-5 grid grid-cols-2 gap-10">
        <SelectAlimentazione />
        <SelectVeicolo />
      </div>
      {model && listino && engine && veicolo && (
          <VeicoloSummary veicolo={veicolo} />
      )}
    </div>
  )
}

export default Step1

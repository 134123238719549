import { Button, NumberFormatter, Tooltip } from "@mantine/core"
import {
  MRT_Cell,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs"
import { useMemo } from "react"
import {
  IconAlertCircle,
  IconCopy,
  IconEdit,
  IconEye,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react"
import {
  useDeletePreventiviMutation,
  useGetPreventiviQuery,
} from "../../features/api/apiSlice"
import { Preventivo } from "../../queries/preventivi"
import { Link, useParams } from "react-router-dom"
import PdfProvider from "../Preventivo/PdfProvider/PdfProvider"
import { transformTimestampToLocaleDateAndTime } from "../../utils/utils"
import {
  deleteModalProps,
  onModalDelete,
} from "../Modal/ModalDelete/ModalDelete"
import { modals } from "@mantine/modals"
import { getColorFromTemperature } from "../../utils/utils"
import { TS4Display } from "../../utils/formatters"
import { Highlight } from "@mantine/core"
import NotificationForm from "../Form/NotificationForm"
import useAuthUserData from "../../hooks/useAuthUserData"

const PreventiviTable = () => {
  const { authUser, isAdmin, authUserId } = useAuthUserData()
  const { customerId } = useParams()
  const [deletePreventivi] = useDeletePreventiviMutation()

  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: "_id",
        header: "Num",
        enableHiding: false,
        size: 70,
        Cell: ({
          row,
          renderedCellValue,
        }: {
          row: MRT_Row<Preventivo>
          renderedCellValue: string
        }) => {
          return (
            <Link to={`/preventivi/visualizza/${row.original._id}`}>
              {renderedCellValue}
            </Link>
          )
        },
      },
      {
        accessorFn: (row: Preventivo) => {
          let date = new Date(TS4Display(+row?.first_saved_at))
          return date
        },
        id: "started_at",
        accessorKey: "started_at",
        header: "Data",
        filterVariant: "date-range",
        enableColumnFilterModes: false,
        enableHiding: false,
        size: 100,
        Cell: ({ row }: { row: MRT_Row<Preventivo> }) => (
          <p>
            {transformTimestampToLocaleDateAndTime(
              row.original.first_saved_at,
              true,
              true,
            )}
          </p>
        ),
      },
      {
        accessorKey: "temperatura",
        header: "Temp",
        enableHiding: false,
        enableColumnOrdering: false,
        enableSorting: false,
        size: 40,
        Cell: ({ row }: { row: MRT_Row<Preventivo> }) => (
          <span
            //@ts-ignore
            className={`mx-auto block h-3 w-3 rounded-full ${getColorFromTemperature(row.original?.temperatura)}`}
          ></span>
        ),
      },
      {
        accessorKey: "price_total",
        header: "Importo",
        enableHiding: false,
        size: 100,
        Cell: ({ row }: { row: MRT_Row<Preventivo> }) => (
          <div>
            <NumberFormatter
              //@ts-ignore
              value={row.original.price_total?.toFixed(2)}
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
            />
            &nbsp;€
          </div>
        ),
      },
      {
        accessorKey: "cliente.nome",
        header: "Cliente",
        enableHiding: false,
        size: 100,
        Cell: ({
          row,
          renderedCellValue,
        }: {
          row: MRT_Row<Preventivo>
          renderedCellValue: string
        }) => (
          <Link to={`/clienti/${row.original.cliente?._id}/panoramica`}>
            {renderedCellValue}
          </Link>
        ),
      },
      {
        accessorKey: "veicolo.description",
        filterFn: "veicoloFilterFn",
        header: "Modello",
        enableHiding: false,
        size: 50,
        Cell: ({
          cell,
          table,
          renderedCellValue,
        }: {
          cell: MRT_Cell<Preventivo>
          table: any
          renderedCellValue: string
        }) => {
          const globalFilterValue = table.getState().globalFilter || ""
          const filterValue =
            (table
              .getColumn("veicolo.description")
              ?.getFilterValue() as string) || ""
          const wordsToHighlight = filterValue.toLowerCase().trim().split(" ")
          const cellValue = cell.getValue<string>()

          return (
            <Highlight
              highlight={wordsToHighlight}
              highlightStyles={(theme) => ({
                backgroundColor: theme.colors.yellow[2],
                padding: 0,
              })}
            >
              {globalFilterValue ? renderedCellValue : cellValue}
            </Highlight>
          )
        },
      },
    ]

    if (isAdmin) {
      //@ts-ignore
      baseColumns.push({
        accessorKey: "agente.name",
        header: "Agente",
        enableHiding: false,
        size: 100,
      })
    }

    return baseColumns
  }, [isAdmin])

  const { data, isLoading, refetch } = useGetPreventiviQuery({
    idCliente: customerId || undefined,
    idAgente: isAdmin ? undefined : authUserId,
  })

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: data?.preventivis || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    showSkeletons: true,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    filterFns: {
      veicoloFilterFn: (row, id, filterValue) => {
        const wordsInFilter = filterValue.toLowerCase().trim().split(" ")
        const veicoloDescription = row.original.veicolo.description
          .toLowerCase()
          .trim()
        return wordsInFilter.every((searchWord: string) =>
          veicoloDescription.includes(searchWord),
        )
      },
    },
    mantineFilterDateInputProps: ({ rangeFilterIndex }) => {
      return {
        placeholder: rangeFilterIndex === 0 ? "Da" : "a",
      }
    },
    state: {
      isLoading: isLoading,
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderRowActionMenuItems: ({ row }) => (
      <div className="flex items-center gap-3">
        <Tooltip label="Visualizza" position="bottom">
          <Link to={`/preventivi/visualizza/${row.original._id}`}>
            <Button
              variant="transparent"
              className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            >
              <IconEye className="h-5 w-5" />
            </Button>
          </Link>
        </Tooltip>
        <Tooltip label="Modifica" position="bottom">
          <Link to={`/preventivi/modifica/${row.original._id}`}>
            <Button
              variant="transparent"
              className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            >
              <IconEdit className="h-5 w-5" />
            </Button>
          </Link>
        </Tooltip>
        <Tooltip label="Duplica" position="bottom">
          <Link to={`/preventivi/duplica/${row.original._id}`}>
            <Button
              variant="transparent"
              className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            >
              <IconCopy className="h-5 w-5" />
            </Button>
          </Link>
        </Tooltip>
        <Tooltip label="Notifica" position="bottom">
          <Button
            variant="transparent"
            className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            onClick={() => {
              modals.open({
                padding: 0,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                centered: true,
                children: (
                  <NotificationForm
                    preventivoId={row.original._id}
                    authUser={authUser}
                  />
                ),
              })
            }}
          >
            <IconAlertCircle className="h-5 w-5" />
          </Button>
        </Tooltip>
        <Tooltip label="Anteprima" position="bottom">
          <span>
            <PdfProvider
              preventivoId={row.original._id || "0000"}
              isIconOnly={true}
            />
          </span>
        </Tooltip>
        <Tooltip label="Elimina" position="bottom">
          <Button
            variant="transparent"
            className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            onClick={() =>
              modals.openConfirmModal({
                ...deleteModalProps("preventivo", "preventivi", [
                  row.original._id || "",
                ]),
                onConfirm: () =>
                  onModalDelete({
                    selectedIds: [row.original._id || ""],
                    deleteFunction: deletePreventivi,
                    singularElement: "preventivo",
                    pluralElement: "preventivi",
                    table: table,
                  }),
              })
            }
          >
            <IconTrash className="h-5 w-5" />
          </Button>
        </Tooltip>
      </div>
    ),
    renderDetailPanel: ({ row }) => (
      <div>
        <p>{row.original.cliente?.cf}</p>
        <p>{row.original.cliente?.localita}</p>
        <p>{row.original.cliente?.tel}</p>
        <p>{row.original.notes}</p>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1

      const handleDelete = () => {
        const selectedIds = table
          .getSelectedRowModel()
          .flatRows.map((row) => row.original._id || "")

        modals.openConfirmModal({
          ...deleteModalProps("preventivo", "preventivi", selectedIds),
          onConfirm: () =>
            onModalDelete({
              selectedIds: selectedIds,
              deleteFunction: deletePreventivi,
              singularElement: "preventivo",
              pluralElement: "preventivi",
              table: table,
            }),
        })
      }

      const handleNotificationClick = (preventivoId: string) => {
        modals.open({
          padding: 0,
          withCloseButton: false,
          size: "70%",
          radius: 26,
          centered: true,
          children: (
            <NotificationForm preventivoId={preventivoId} authUser={authUser} />
          ),
        })
      }

      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <>
                  <Link
                    to={`/preventivi/visualizza/${table.getSelectedRowModel().flatRows.map((row) => row.original._id)}`}
                  >
                    <Button rightSection={<IconEye size={18} />}>
                      Visualizza
                    </Button>
                  </Link>
                  <Link
                    to={`/preventivi/modifica/${table.getSelectedRowModel().flatRows.map((row) => row.original._id)}`}
                  >
                    <Button rightSection={<IconEdit size={18} />}>
                      Modifica
                    </Button>
                  </Link>
                  <Link
                    to={`/preventivi/duplica/${table.getSelectedRowModel().flatRows.map((row) => row.original._id)}`}
                  >
                    <Button rightSection={<IconCopy size={18} />}>
                      Duplica
                    </Button>
                  </Link>
                  <Button
                    rightSection={<IconAlertCircle size={18} />}
                    onClick={() =>
                      handleNotificationClick(
                        table
                          .getSelectedRowModel()
                          .flatRows.map((row) => row.original._id)
                          .join(","),
                      )
                    }
                  >
                    Notifica
                  </Button>
                </>
              )}
              <Button
                rightSection={<IconTrash size={18} />}
                onClick={handleDelete}
              >
                Elimina
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            {isAdmin && (
              <Button
                variant="outline"
                radius={22}
                leftSection={<IconRefresh size={18} />}
                onClick={refetch}
              >
                Aggiorna
              </Button>
            )}
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      )
    },
  })
  return <MantineReactTable table={table} />
}

export default PreventiviTable

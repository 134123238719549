import { useMemo } from "react";
import {
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import VariabileForm from "../../Form/VariabileForm";
import { Button } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs";
import {
  useDeleteTestoMutation,
  useGetTestisQuery,
} from "../../../features/api/apiSlice";
import {
  deleteModalProps,
  onModalDelete,
} from "../../Modal/ModalDelete/ModalDelete";
import { modals } from "@mantine/modals";

const VariabiliTable = () => {
  const [deleteTesto] = useDeleteTestoMutation();
  const { data, isLoading } = useGetTestisQuery({
    limit: 10000,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "label",
        header: "Label",
        enableHiding: false,
      },
      {
        accessorKey: "testo",
        header: "Testo",
        enableHiding: false,
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: data?.testis || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: "contains",
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    state: {
      isLoading: isLoading,
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },

    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0;
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1;

      const handleEdit = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          modals.open({
            padding: 0,
            withCloseButton: false,
            centered: true,
            size: "70%",
            radius: 26,
            children: (
              <VariabileForm
                currentModalRecord={row.original}
                onCloseClick={() => modals.closeAll()}
              />
            ),
          });
        });
      };

      const handleDelete = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          modals.openConfirmModal({
            ...deleteModalProps("variabile", "variabili", [
              row.original?._id || "",
            ]),
            onConfirm: () =>
              onModalDelete({
                selectedIds: [row.original._id || ""],
                deleteFunction: deleteTesto,
                singularElement: "variabile",
                pluralElement: "variabili",
                table: table,
              }),
          });
        });
      };

      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <Button
                  leftSection={<IconEdit size={18} />}
                  onClick={handleEdit}
                >
                  Modifica
                </Button>
              )}
              <Button
                leftSection={<IconTrash size={18} />}
                onClick={handleDelete}
              >
                Elimina
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      );
    },
  });

  return <MantineReactTable table={table} />;
};

export default VariabiliTable;
